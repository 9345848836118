import React, {useState} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import {Link} from "gatsby";
import Formsy from "formsy-react";
import queryString from "query-string";
import root from "window-or-global";

import Manager from "../components/manager";
import Theme from "../configs/theme";

import FormsyTextInput from "../components/formsy/text-field";
import {setPasswordMutation} from "../logic/user";
import fetch from "../logic/utils/fetch";

export default function ResetPassword(props) {
  const {headerImage} = props;
  const [state, setState] = useState({
    error: false,
    success: false,
    message: "",
  });

  const search = ((root || {}).location || {}).search;
  const {t: code, u: id} = queryString.parse(search);
  if ((!code || !id) && root.location) {
    return root.location.replace("/login");
  }

  async function handleSubmit({password, confirmPassword}) {
    if (password !== confirmPassword) {
      return setState({
        error: true,
        message: "New Password and Confirm New Password does not match",
      });
    }

    const result = await fetch.gql(setPasswordMutation, {id, code, password});
    console.log(result.data.classMethods.UserAuth.setPassword)
    if (result.data.classMethods.UserAuth.setPassword === false) {
      return setState({
        error: true,
        message: "Authentication token already expired",
      });
    }

    return setState({
      error: false,
      success: true,
    });
  }

  return (
    <Manager name="login" title="Forgotten Password">
      <Theme>
        <Container>
          {(headerImage && headerImage !== "") && (
            <Row>
              <Col>
                <img alt="" src={headerImage} />
              </Col>
            </Row>
          )}
          {state.success ? (
            <>
              <Row>
                <Col>
                  <div className="alert alert-success">
                    {"You have successfully changed your password!"}
                  </div>
                </Col>
              </Row>
              <Row className="login-button-row">
                <Col>
                  <Link className="register-link" to="/">{"Login"}</Link>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Formsy className="frm-login" onValidSubmit={handleSubmit}>
                <Row style={{marginBottom: 30}}>
                  <Col xs="auto" className="mx-auto">
                    <div className="login-title">
                      {"Kindly enter your new and confirmation password."}
                    </div>
                  </Col>
                </Row>
                {state.error && (
                  <Row>
                    <Col>
                      <div className="alert alert-danger">
                        {state.message}
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="field-row username-field">
                  <Col>
                    <FormsyTextInput
                      label="New Password"
                      type="password"
                      name="password"
                      placeholder="New Password"
                      testid="password"
                      disabled={state.isSubmitted}
                      validationError="This field is required"
                      required
                    />
                  </Col>
                </Row>
                <Row className="field-row username-field">
                  <Col>
                    <FormsyTextInput
                      label="Confirm New Password"
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                      testid="confirm-password"
                      disabled={state.isSubmitted}
                      validationError="This field is required"
                      required
                    />
                  </Col>
                </Row>
                <Row className="login-button-row">
                  <Col xs={12} sm={6}>
                    <Button
                      type={"submit"}
                      style={{width: "100%"}}
                      colour="primary">
                      {"Save"}
                    </Button>
                  </Col>
                </Row>
              </Formsy>
            </>
          )}
        </Container>
      </Theme>
    </Manager>
  );
}
